import * as React from "react";
import { Link } from "gatsby";
import PageTemplate from '../components/page-template';

// markup
const NotFoundPage = () => {
  return (
    <PageTemplate className="my-5">
      <h1>Not Found</h1>
      <p>Oops, it looks like this page does not exist. You can get started from <Link to="/">the home page</Link>.</p>
    </PageTemplate>
  )
}

export default NotFoundPage
